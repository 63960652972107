/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Input, message, notification } from 'antd';
import { Banknote, ShoppingBag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { BasketProductsList } from '../sidemenu/components';
import './checkout.css';
import checkoutApi from './checkout.api';
import { DataLoadingOverlay } from '../common/components';
import { ThemeContext } from '../../contexts/theme.context';
import utils from '../common/services/utils.service';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';
import { TBrandDetails } from '../common/types';
import useCurrentUser from '../hooks/useCurrentUser.hook';
import sharedApi from '../common/shared.api';

const { TextArea } = Input;

const paymentMethod = {
  cash: 'cash',
  online: 'platform_credit',
};

const Checkout: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const { currentUser } = useCurrentUser();
  const { orderCreationStore, setOrderCreationStore } = useOrderCreationStore();
  const { orderTicket } = orderCreationStore;
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const redirectStatus = urlParams.get('redirect_status');
  const [orderNote, setOrderNote] = useState(orderTicket.order_notes || '');
  const [allowedPaymentMethods, setAllowedPaymentMethods] = useState<string[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'cash' | 'online'>('cash');
  const iBlinkMarketplaceCustomer = JSON.parse(localStorage.getItem('iBlinkMarketplaceUser') || '{}');
  const currencySymbol = orderTicket?.payment_details.currency_name?.toUpperCase() || 'AED';
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');
  const orderType = localStorage.getItem('orderType');
  const [isLoading, setIsLoading] = useState(false);

  const fetchPaymentMethods = useQuery(
    'fetchPaymentMethods',
    () => checkoutApi.getPaymentMethods(orderTicket.integration_details.branch_id || 0),
    {
      enabled: false,
      onSuccess: ({ data, status }) => {
        if (status === 200 && data.payload) {
          let paymentMethods: string[] = [];
          data.payload.forEach((method) => {
            if (method.payment_method_name === 'cash') paymentMethods = [...paymentMethods, 'cash'];
            if (method.payment_method_name === 'platform_credit') paymentMethods = [...paymentMethods, 'online'];
          });
          setAllowedPaymentMethods(paymentMethods);
          if (paymentMethods.length > 0) setSelectedPaymentMethod(paymentMethods[0] as 'cash' | 'online');
        }
      },
    },
  );

  const getOrderType = () => {
    if (orderCreationStore.orderTicket.integration_details.fulfillment_type_id === 4) {
      return `Pickup`;
    }
    if (orderCreationStore.orderTicket.integration_details.fulfillment_type_id === 3) return 'Delivery';

    if (orderTicket.integration_details.fulfillment_type === 'dine-in') return 'Dine In';

    return 'Select Order Type';
  };

  const placeOrder = async (onlinePayment = false) => {
    setIsLoading(true);
    const { data, status } = await checkoutApi.placeOrder({
      ...orderTicket,
      payment_details: {
        ...orderTicket.payment_details,
        currency_name: currencySymbol,
        payment_method_name:
          orderType === 'dine-in'
            ? null
            : onlinePayment
            ? paymentMethod.online
            : paymentMethod[selectedPaymentMethod] || null,
        paid_p: onlinePayment ? 1 : 0,
      },
    });
    if (status === 200) {
      localStorage.setItem('customerOrderId', data.customer_order_id);
      setOrderCreationStore({
        ...orderCreationStore,
        orderTicket: {
          ...orderTicket,
          item_list: [],
          payment_details: {
            ...orderTicket.payment_details,
            payable_amount: 0,
            tax_amount: 0,
            tip_amount: 0,
            discount_amount: 0,
          },
        },
      });
      notification.success({
        message: 'Order Created Successfully',
        description: `Order # ${data.customer_order_id} Created Successfully.`,
        placement: isMobile ? 'bottom' : 'topRight',
        duration: 1.5,
      });
      if (orderType === 'dine-in') {
        const { status: paymentStatus } = await sharedApi.generatePaymentLinkByOrderId(data.customer_order_id);
        console.log('paymentStatus', paymentStatus);
      }
      setTimeout(() => {
        navigate(`/order-summary`);
      }, 1000);
    } else {
      setIsLoading(false);
      notification.error({
        message: 'Error',
        description: 'Something went wrong, please try again later.',
        placement: isMobile ? 'bottom' : 'topRight',
        duration: 1.5,
      });
    }
  };

  const validateOrder = async () => {
    setIsLoading(true);
    const paymentDetails = orderTicket.payment_details;
    setOrderCreationStore({
      ...orderCreationStore,
      orderTicket: {
        ...orderTicket,
        order_notes: orderNote,
      },
    });
    if (selectedPaymentMethod === 'online' && orderType !== 'dine-in') {
      const { status } = await checkoutApi.validateOrder(orderTicket);

      if (status === 200) {
        const payload = {
          version: '0.1',
          test_p: 0,
          direct_p: 1,
          external_reference_code: null,
          remote_transaction_id: null,
          merchant_code: brandDetails?.merchant.merchant_code || '',
          settings: {
            redirect_url_success: window.location.href,
            redirect_url_error: window.location.href,
            payment_link_expiry_duration: null,
            expiry_duration_measurement_name: null,
          },
          payment_details: {
            payable_amount: paymentDetails.payable_amount,
            tax_amount: paymentDetails.tax_amount,
            tip_amount: paymentDetails.tip_amount,
            discount_amount: paymentDetails.discount_amount,
            currency_name: paymentDetails.currency_name || 'aed',
            payment_method_name: 'card',
          },
          customer_details: {
            customer_id: orderTicket.customer_details.customer_id || currentUser?.customer.customer_id || undefined,
            external_customer_id: null,
            first_name_en: null,
            last_name_en: null,
            email: null,
            phone_nr: null,
            customer_contact_method: null,
          },
          customer_address_details: orderTicket.customer_address_details?.customer_address_id
            ? {
                customer_address_id: String(orderTicket.customer_address_details?.customer_address_id),
              }
            : undefined,
          employee_details: {
            external_employee_id: null,
            first_name_en: null,
            last_name_en: null,
            email: null,
            phone_nr: null,
          },
          item_list: [],
          offer_list: [],
        };
        const { data: paymentData, status: paymentStatus } = await checkoutApi.generatePaymentLink(payload);

        if (paymentStatus === 200 && paymentData.service_provider_name === 'stripe' && paymentData?.payment_url) {
          window.location.href = paymentData.payment_url;
        } else if (
          paymentStatus === 200 &&
          paymentData.service_provider_name === 'telr' &&
          paymentData?.service_provider_url
        ) {
          window.location.href = paymentData.service_provider_url;
        } else {
          message.error('Something went wrong, please try again later');
          setIsLoading(false);
        }
      } else {
        message.error('Something went wrong, please try again later');
        setIsLoading(false);
      }
    } else {
      setTimeout(() => {
        placeOrder();
      }, 10);
    }
  };

  useEffect(() => {
    if (navigate) if (orderType !== 'dine-in' && !iBlinkMarketplaceCustomer?.phoneNumber) navigate('/menu');
  }, [iBlinkMarketplaceCustomer, navigate]);

  useEffect(() => {
    if (orderTicket.item_list.length === 0 && orderTicket.offer_list.length === 0) navigate('/menu');
    fetchPaymentMethods.refetch();
  }, []);

  useEffect(() => {
    if (redirectStatus === 'succeeded') {
      placeOrder(true);
    } else if (['declined', 'cancelled'].includes(redirectStatus || '')) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong, please try again later.',
        placement: isMobile ? 'bottom' : 'topRight',
        duration: 1.5,
      });
    }
  }, [redirectStatus]);

  return (
    <div
      className="md:grid md:grid-cols-2 md:gap-10 md:px-36 h-full"
      style={{ backgroundColor: theme?.general?.backgroundColor || '' }}
    >
      <DataLoadingOverlay isLoading={isLoading} />
      <div
        className="pt-1 bg-gray-100 w-full relative pb-36 md:pb-0 md:shadow-lg h-full"
        style={{ backgroundColor: theme?.general?.backgroundColor || '' }}
      >
        <div className="flex justify-center">
          <button
            type="button"
            className="px-2 mb-1 ml-1 py-1 gap-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex justify-center items-center"
            onClick={() => {
              navigate('/menu');
            }}
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
          >
            <span>Forgot something? Go back to Menu</span>
          </button>
        </div>
        {iBlinkMarketplaceCustomer && orderType !== 'dine-in' ? (
          <div className="flex gap-2 whitespace-nowrap items-center px-4">
            <span className="font-semibold">Contact Number:</span>
            <span>{iBlinkMarketplaceCustomer?.phoneNumber}</span>
          </div>
        ) : null}
        <div className="flex gap-2 whitespace-nowrap items-center px-4">
          <span className="font-semibold">Order Type:</span>
          {orderTicket.integration_details.fulfillment_type !== 'dine-in' ? (
            <button
              type="button"
              className="px-2 capitalize w-max py-2 m-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex gap-1 justify-center items-center"
              onClick={() => {
                navigate('/order-type');
              }}
              style={{ backgroundColor: theme?.general?.buttonColor || '' }}
            >
              <ShoppingBag width={16} />
              <span>{getOrderType()}</span>
            </button>
          ) : (
            'Dine In'
          )}
        </div>
        {orderCreationStore.orderTicket.integration_details.fulfillment_type_id ? (
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <div className="px-4 py-2 flex gap-2">
              <span className="font-semibold whitespace-nowrap">
                {orderCreationStore.orderTicket.integration_details.fulfillment_type_id ? getOrderType() : ''} Address:
              </span>
              <span className="inline-block capitalize">
                {orderTicket.integration_details.fulfillment_type === 'dine-in'
                  ? `${orderTicket.dining_area_name} - ${orderTicket.dining_table_name}`
                  : orderCreationStore.address?.order_address}
              </span>
            </div>
          </div>
        ) : null}
        <div className="flex mt-4 md:mt-0 md:hidden">
          <input
            type="text"
            placeholder="Order Notes"
            className={`px-2 py-4 w-full placeholder-gray-400 border border-gray-200 rounded-md shadow-md mx-2 placeholder-[${
              theme?.general?.textColor || '#6b7280'
            }]`}
            style={{
              backgroundColor: theme?.general?.cardBackgroundColor || '',
              color: theme?.general?.textColor || '',
              borderColor: theme?.general?.backgroundColor || '',
            }}
          />
        </div>
        <div className="mt-2">
          {orderType !== 'dine-in' && allowedPaymentMethods.length !== 0 ? (
            <div className="grid grid-cols-2 pt-4 pb-2">
              <div className="ml-4 text-black font-semibold text-lg">Payment Method</div>
              <button type="button" className="hidden text-right mr-4 font-bold text-gray-300" disabled>
                Change
              </button>
            </div>
          ) : null}
          {orderType !== 'dine-in' ? (
            <div className="mx-4 mb-2 flex flex-col md:flex-row gap-2">
              {allowedPaymentMethods.includes('cash') ? (
                <button
                  type="button"
                  className={utils.cn('w-full rounded-md shadow-md border py-2 px-2 flex gap-2 items-center', {
                    'ring-colorPrimary ring-2': selectedPaymentMethod === 'cash',
                  })}
                  onClick={() => setSelectedPaymentMethod('cash')}
                >
                  <Banknote width={40} height={40} color="green" className="mr-2" />
                  <div className="text-black font-semibold">Cash</div>
                </button>
              ) : null}
              {allowedPaymentMethods.includes('online') ? (
                <button
                  type="button"
                  className={utils.cn('w-full rounded-md shadow-md border py-2 px-2 flex gap-2 items-center', {
                    'ring-colorPrimary ring-2': selectedPaymentMethod === 'online',
                  })}
                  onClick={() => setSelectedPaymentMethod('online')}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/payment.png`}
                    alt="Credit / Debit Card"
                    className="w-10 float-left -mt-1 mr-2"
                  />
                  <div className="text-black font-semibold">Online</div>
                </button>
              ) : null}
            </div>
          ) : null}
          <h1 className="font-bold text-lg ml-4">Order Details</h1>
          <div className="flex justify-center">
            <hr className="px-2 my-2 w-[95%]" />
          </div>
          <div className="overflow-y-auto md:max-h-[60vh] items-container px-2 pb-2">
            <BasketProductsList containerStyle="" subTotalHidden allowQuantityChange={false} />
          </div>
          <div className="flex justify-center">
            <hr className="px-2 my-2 w-[95%]" />
          </div>
        </div>
        <div className="md:hidden pb-48" />
        <div
          className="fixed bg-white md:hidden rounded-t-2xl bottom-0 left-0 right-0 w-full shadow-md border-colorPrimary neon-slate-light"
          style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
        >
          <div className="grid grid-cols-2 py-1 pt-3">
            <div className="ml-4 text-base">Subtotal</div>
            <div className="text-right mr-4">
              {currencySymbol}{' '}
              {(
                orderTicket.item_list.map((item) => item.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
                orderTicket.offer_list.map((offer) => offer.subTotal || 0).reduce((prev, curr) => prev + curr, 0)
              ).toFixed(2)}
            </div>
          </div>
          {orderTicket.payment_details.delivery_amount ? (
            <div className="grid grid-cols-2 py-1">
              <div className="ml-4 text-base">Delivery Amount</div>
              <div className="text-right mr-4">
                {currencySymbol} {(orderTicket.payment_details.delivery_amount || 0).toFixed(2)}
              </div>
            </div>
          ) : null}
          <div className="grid grid-cols-2 py-1">
            <div className="ml-4 text-base font-semibold">Order Total</div>
            <div className="text-right mr-4 font-bold">
              {currencySymbol}{' '}
              {(
                orderTicket.item_list.map((item) => item.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
                orderTicket.offer_list.map((offer) => offer.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
                (orderTicket.payment_details.delivery_amount || 0)
              ).toFixed(2)}
            </div>
          </div>
          <div className="flex justify-center my-3">
            <button
              type="button"
              onClick={() => validateOrder()}
              className="bg-colorPrimary text-center rounded-md w-[90%] py-3 text-textPrimary text-lg font-semibold"
              style={{ backgroundColor: theme?.general?.buttonColor || '' }}
              disabled={orderTicket.integration_details.fulfillment_type_id === 0 || isLoading}
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
      <div>
        <div className="hidden md:block h-full w-80 p-4 float-right md:shadow-lg">
          <h1 className="font-semibold">Order Summary</h1>
          <TextArea
            className={`mt-4 resize-none rounded-lg placeholder-[${theme?.general?.textColor || '#6b7280'}]`}
            placeholder="Order Notes"
            rows={4}
            style={{
              backgroundColor: theme?.general?.cardBackgroundColor || '',
              color: theme?.general?.textColor || '',
              borderColor: theme?.general?.backgroundColor || '',
            }}
            onChange={(e) => setOrderNote(e.target.value)}
            count={undefined}
          />
          <div className="m-2 pt-6">
            <div className="grid grid-cols-2 mt-2">
              <div className="text-gray-500" style={{ color: theme?.general?.descriptionTextColor || '' }}>
                Subtotal
              </div>
              <div className="text-right text-gray-500" style={{ color: theme?.general?.descriptionTextColor || '' }}>
                {currencySymbol}{' '}
                {(
                  orderTicket.item_list.map((item) => item.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
                  orderTicket.offer_list.map((offer) => offer.subTotal || 0).reduce((prev, curr) => prev + curr, 0)
                ).toFixed(2)}
              </div>
            </div>
            {orderTicket.payment_details.delivery_amount ? (
              <div className="grid grid-cols-2 mt-2">
                <div className="text-gray-500">Delivery</div>
                <div className="text-right text-gray-500">
                  {currencySymbol} {(orderTicket.payment_details.delivery_amount || 0).toFixed(2)}
                </div>
              </div>
            ) : null}
            <hr className="my-2" />
            <div className="grid grid-cols-2">
              <div className="font-bold">Order Total</div>
              <div className="text-right font-bold">
                {currencySymbol}{' '}
                {(
                  orderTicket.item_list.map((item) => item.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
                  orderTicket.offer_list.map((offer) => offer.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
                  (orderTicket.payment_details.delivery_amount || 0)
                ).toFixed(2)}
              </div>
            </div>
            <div className="flex justify-center mt-6">
              <button
                type="button"
                onClick={() => validateOrder()}
                className="bg-colorPrimary text-center rounded-md w-[90%] py-2 text-textPrimary text-lg font-semibold disabled:opacity-50"
                style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                disabled={orderTicket.integration_details.fulfillment_type_id === 0 || isLoading}
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
