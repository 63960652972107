import { useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { BasketProductsList, OtpModal } from '../../../sidemenu/components';
import { ThemeContext } from '../../../../contexts/theme.context';
import PhoneModal, { ModalHandle } from '../../../sidemenu/components/phone-modal/phone-modal.component';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';

type BasketViewProps = {
  hideDrawer?: () => void;
};

const BasketView = ({ hideDrawer }: BasketViewProps) => {
  const { theme } = useContext(ThemeContext);
  const { orderCreationStore } = useOrderCreationStore();
  const { orderTicket } = orderCreationStore;
  const currencySymbol = orderTicket.payment_details.currency_name?.toUpperCase();
  const phoneModalRef = useRef<ModalHandle>(null);
  const otpModalRef = useRef<ModalHandle>(null);
  const navigate = useNavigate();
  const orderType = localStorage.getItem('orderType') || 'delivery';

  const checkoutPressed = () => {
    if (orderType === 'dine-in') {
      hideDrawer?.();
      navigate('/checkout');
    } else {
      const iBlinkMarketplaceCustomer = JSON.parse(localStorage.getItem('iBlinkMarketplaceUser') || 'null');
      if (iBlinkMarketplaceCustomer) {
        Modal.confirm({
          title: 'Continue with current user?',
          content: `You are already logged in with phone number ${iBlinkMarketplaceCustomer?.phoneNumber} . Do you want to continue with this mobile number?`,
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            hideDrawer?.();
            navigate('/checkout');
          },
          onCancel: () => {
            localStorage.removeItem('iBlinkMarketplaceUser');
            localStorage.removeItem('phoneNumber');
            phoneModalRef.current?.toggleModal();
          },
        });
      } else phoneModalRef.current?.toggleModal();
    }
  };
  const phoneContinuePressed = (userAlreadyExists = false) => {
    if (userAlreadyExists) {
      hideDrawer?.();
      navigate('/checkout');
    } else otpModalRef.current?.toggleModal();
  };
  const otpContinuePressed = () => {
    hideDrawer?.();
    navigate('/checkout');
  };

  return (
    <>
      <PhoneModal ref={phoneModalRef} continuePressed={phoneContinuePressed} />
      <OtpModal ref={otpModalRef} continuePressed={otpContinuePressed} />
      {orderTicket.item_list && (
        <div
          className="py-4 bg-gray-50 w-full relative md:static h-fill overflow-y-auto md:pb-24"
          style={{
            backgroundColor: theme?.general?.backgroundColor || '',
            color: theme?.general?.textColor || '',
          }}
        >
          {/* <div className="p-4 bg-white">
          <HorizontalSlider
            title="You May Also Like"
            titleSize="text-lg"
            imagesArray={relatedProducts}
            spacing="grid-cols-[repeat(auto-fill,minmax(60px,1fr))] grid-flow-col auto-cols-[minmax(60px,1fr)]"
            imageContainerSize="h-24 w-16"
            imageSize="h-16 w-16"
            showImageName
          />
        {/* <div className="grid grid-cols-2 bg-white p-6 mt-4">
          <div className="text-md">Cutlery</div>
          <div className="flex justify-end">
            <Switch />
          </div>
        </div> */}
          {/* <div className="flex bg-white mt-2">
            <input type="text" placeholder="Order Notes" className="px-6 py-4 w-full border-none" />
          </div> */}

          <BasketProductsList
            containerStyle={window.location.pathname === '/basket' ? 'pb-40' : undefined}
            showManualCombinationRecommendations
          />

          <div
            className="fixed md:absolute rounded-t-md bg-white bottom-0 left-0 right-0 border-t pt-2"
            style={{
              backgroundColor: theme?.general?.cardBackgroundColor || '',
              borderColor: theme?.general?.backgroundColor || '',
            }}
          >
            <div className="grid grid-cols-2 py-1">
              <div className="ml-4">Sub Total</div>
              <div className="text-right mr-4 font-bold">
                {currencySymbol}{' '}
                {(
                  orderTicket.payment_details.payable_amount +
                  orderTicket.payment_details.discount_amount -
                  orderTicket.payment_details.delivery_amount
                ).toFixed(2)}
              </div>
            </div>
            {orderTicket.payment_details.delivery_amount ? (
              <div className="grid grid-cols-2 py-1">
                <div className="ml-4">Delivery Amount</div>
                <div className="text-right mr-4 font-bold">
                  {currencySymbol} {orderTicket.payment_details.delivery_amount.toFixed(2)}
                </div>
              </div>
            ) : null}
            <div className="grid grid-cols-2 py-1">
              <div className="ml-4">Grand Total</div>
              <div className="text-right mr-4 font-bold">
                {currencySymbol} {orderTicket.payment_details.payable_amount.toFixed(2)}
              </div>
            </div>
            <div className="flex justify-center my-4">
              <button
                type="button"
                disabled={
                  (orderTicket.item_list.length === 0 && orderTicket.offer_list.length === 0) ||
                  orderTicket.payment_details.payable_amount < 0
                }
                onClick={checkoutPressed}
                className="bg-colorPrimary text-center rounded-md w-[90%] py-3 text-textPrimary text-lg font-semibold disabled:opacity-50"
                style={{ backgroundColor: theme?.general?.buttonColor || '' }}
              >
                Go To Checkout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

BasketView.defaultProps = {
  hideDrawer: () => {},
};

export default BasketView;
