/* eslint-disable no-nested-ternary */
import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useContext } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { BasketView } from './components';
import { ThemeContext } from '../../contexts/theme.context';
import useOrderCreationStore from '../hooks/useOrderCreation.hook';

const Basket: React.FC = () => {
  const { theme } = useContext(ThemeContext);
  const { orderCreationStore, setOrderCreationStore } = useOrderCreationStore();
  const navigate = useNavigate();
  // onClick={() => {
  //   localStorage.removeItem('selectedPickupLocation');
  //   localStorage.setItem('selectedDeliveryLocation', JSON.stringify(selectedDeliveryLocation));
  // }}

  return (
    <div className="px-0" style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}>
      <div className="flex justify-between">
        <button
          type="button"
          className="px-2 w-2/5 py-2 m-1 text-white bg-colorPrimary rounded-md hover:bg-colorSecondary flex gap-1 justify-center items-center"
          onClick={() => {
            setOrderCreationStore({
              ...orderCreationStore,
              orderTicket: {
                ...orderCreationStore.orderTicket,
                item_list: [],
              },
            });
          }}
          style={{ backgroundColor: theme?.general?.buttonColor || '' }}
        >
          <TrashIcon width={16} />
          <span>Clear basket</span>
        </button>
        <button
          type="button"
          className="m-1 text-gray-600 flex justify-center items-center"
          onClick={() => {
            navigate('/menu');
          }}
        >
          <XMarkIcon width={32} color={theme?.general?.textColor || 'black'} />
        </button>
      </div>
      <BasketView />
    </div>
  );
};

export default Basket;
