/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import utils from '../../services/utils.service';
import { TMenuItem, TOrderLineItem } from '../../types';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import { useIsLoading, useRecommendationModal } from '../../hooks';
import { ThemeContext } from '../../../../contexts/theme.context';

const RecommendationModal = () => {
  const { setIsLoading } = useIsLoading();
  const navigate = useNavigate();
  const { recommendationStore, setOpen } = useRecommendationModal();
  const { open, recommendations, recommendedItemIds } = recommendationStore;
  const { orderCreationStore, addLineItemToTicket } = useOrderCreationStore();
  const { menu } = orderCreationStore;
  const { theme } = useContext(ThemeContext);
  const [recommendedItems, setRecommendedItems] = useState<TMenuItem[]>([]);

  const showNotification = (menuItem: TMenuItem) => {
    toast.info(
      <div>
        <img
          src={
            menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
              ? menuItem?.menu_item_images[0]?.menu_item_image_url
              : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
          }
          alt="product"
          className="h-16 w-16 object-cover float-left rounded-lg"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`;
          }}
        />
        <div className="ml-20 capitalize">
          <b>{menuItem?.menu_item_name_en}</b>
        </div>
        <div className="ml-20">(X1) Added to basket</div>
      </div>,
    );
  };

  const onAddMenuItem = async (item: TMenuItem) => {
    setIsLoading(true);
    const recommendation = (recommendations as any[]).find((r) =>
      r.recommendation_type === 'manual-push' ? r.item_id === item.item_id : r.recommended_item_id === item.item_id,
    );
    const orderLineItem: TOrderLineItem = {
      id: uuidv4(),
      external_item_id: item.menu_item_external_id,
      item_id: item.item_id,
      item_category_id: item.item_category_id || 0,
      display_name_en: item.menu_item_name_en,
      display_name_ar: item.menu_item_name_ar,
      menu_item_image: item.menu_item_images.length > 0 ? item.menu_item_images[0]?.menu_item_image_url : '',
      item_price: item.menu_item_price,
      price: item.menu_item_price,
      item_price_list: item.item_price_list || [],
      quantity: 1,
      modifier_section_list: [],
      modifierSubtotal: 0,
      currency_alpha_3: item.currency_alpha_3,
      menu_item_recommendation_id: recommendation?.menu_item_recommendation_id,
    };
    if (
      item.modifier_sections.length === 0 ||
      item.modifier_sections.every((section) => section.section_type === 'optional')
    ) {
      const itemAdded = await addLineItemToTicket(orderLineItem);
      if (itemAdded) {
        showNotification(item);
        setOpen(false);
      } else
        notification.error({
          message: 'Something went wrong',
          description: `Unable to add ${item.menu_item_name_en} to the basket, please try again later`,
          placement: isMobile ? 'bottom' : 'topRight',
          duration: 1.5,
        });
    } else {
      navigate(`/product/${item.item_id}?recommended=${recommendation?.menu_item_recommendation_id}`);
      setOpen(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (recommendedItemIds.length > 0) {
      const fulfilmentTypeMenu = menu.map((category) => ({
        ...category,
        menu_items: category.menu_items
          .filter(
            (item) =>
              item.item_price_list.length === 0 ||
              item.item_price_list.find(
                (price) =>
                  price.fulfillment_type_id === orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
              ),
          )
          .map((item) => ({ ...item, item_category_id: category.item_category_id })),
      }));

      const selectedMenuCategory = fulfilmentTypeMenu
        .flatMap((category) => category.menu_items)
        .sort((a, b) => a.menu_item_name_en.localeCompare(b.menu_item_name_en))
        .sort((a, b) => Number(a.excluded_p) - Number(b.excluded_p));

      setRecommendedItems(selectedMenuCategory.filter((item) => recommendedItemIds.includes(item.item_id)));
    } else setRecommendedItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedItemIds]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-[27848181]">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in block"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex h-max justify-center text-center items-center md:px-2 lg:px-4">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span aria-hidden="true" className="inline-block h-screen md:align-middle align-bottom">
            &#8203;
          </span>
          <DialogPanel
            transition
            className="flex items-center w-full mx-4 md:mx-0 transform text-left text-base transition data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in md:my-8 md:max-w-xl md:px-4 data-[closed]:md:translate-y-0 data-[closed]:md:scale-95 lg:max-w-xl"
          >
            <div
              className="relative h-max flex w-full items-center overflow-hidden bg-white shadow-2xl rounded-md"
              style={{ backgroundColor: theme?.general?.backgroundColor || '', color: theme.general?.textColor || '' }}
            >
              <img src="/assets/recommended.png" alt="logo" className="fixed -left-7 -top-6 md:-left-3 h-36 z-10" />
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="fixed right-2 top-2.5 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-3 md:right-6 md:top-6 lg:right-6 lg:top-3 z-50"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>

              <div className="flex flex-col space-y-3 w-full">
                {recommendedItems.map((rItem) => (
                  <div
                    className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-2 lg:items-center lg:gap-x-8"
                    key={rItem.item_id}
                  >
                    <div className="aspect-h-2 aspect-w-2 overflow-hidden rounded-lg bg-gray-100">
                      <img
                        alt={rItem?.menu_item_name_en}
                        src={rItem?.menu_item_images[0]?.menu_item_image_url}
                        className="object-cover object-center"
                        onError={(e) => {
                          e.currentTarget.src = `/assets/products/empty_menu.png`;
                        }}
                      />
                    </div>
                    <div className="p-4">
                      {(recommendations as any)?.find((recommendation: any) =>
                        recommendation.recommendation_type === 'manual-push'
                          ? recommendation.item_id === rItem.item_id
                          : recommendation.recommended_item_id === rItem.item_id,
                      )?.customer_recommendation_p ? (
                        <div className="mb-2">
                          <h4 className="sr-only">Recommendation</h4>

                          <div className="flex gap-2">
                            <img src="/assets/chef-icon-solid.png" alt="logo" className="h-12 inline" />
                            <p
                              className="text-sm text-gray-700"
                              style={{ color: theme.general?.descriptionTextColor || '' }}
                            >
                              {
                                (recommendations as any)?.find((recommendation: any) =>
                                  recommendation.recommendation_type === 'manual-push'
                                    ? recommendation.item_id === rItem.item_id
                                    : recommendation.recommended_item_id === rItem.item_id,
                                )?.customer_message
                              }
                            </p>
                          </div>
                        </div>
                      ) : null}
                      <h2 className="text-xl font-medium sm:pr-12 capitalize">{rItem?.menu_item_name_en}</h2>

                      <section aria-labelledby="information-heading" className="mt-1">
                        <h3 id="information-heading" className="sr-only">
                          Product information
                        </h3>

                        <p
                          className="font-medium w-max p-0.5 px-1 rounded-md"
                          style={{ backgroundColor: theme.general?.buttonColor || '' }}
                        >
                          {utils.formatCurrency(rItem?.menu_item_price || 0, rItem?.currency_alpha_3)}
                        </p>

                        <div className="mt-2">
                          <h4 className="sr-only">Description</h4>

                          <p
                            className="text-sm text-gray-700"
                            style={{ color: theme.general?.descriptionTextColor || '' }}
                          >
                            {rItem?.menu_item_description_en}
                          </p>
                        </div>
                      </section>

                      <section aria-labelledby="options-heading" className="mt-0">
                        <button
                          type="submit"
                          className="bg-colorPrimary text-textPrimary px-2 w-full rounded-md py-3 mt-6"
                          style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                          onClick={() => onAddMenuItem(rItem as TMenuItem)}
                        >
                          Add
                        </button>
                      </section>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:items-center lg:gap-x-8">
                <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                  <img
                    alt={recommendedItem?.menu_item_name_en}
                    src={recommendedItem?.menu_item_images[0].menu_item_image_url}
                    className="object-cover object-center"
                    onError={(e) => {
                      e.currentTarget.src = `/assets/products/empty_menu.png`;
                    }}
                  />
                </div>
                <div className="sm:col-span-8 lg:col-span-7">
                  <h2 className="text-xl font-medium sm:pr-12">{recommendedItem?.menu_item_name_en}</h2>

                  <section aria-labelledby="information-heading" className="mt-1">
                    <h3 id="information-heading" className="sr-only">
                      Product information
                    </h3>

                    <p className="font-medium">
                      {utils.formatCurrency(recommendedItem?.menu_item_price || 0, recommendedItem?.currency_alpha_3)}
                    </p>

                    <div className="mt-6">
                      <h4 className="sr-only">Description</h4>

                      <p className="text-sm text-gray-700">{recommendedItem?.menu_item_description_en}</p>
                    </div>
                  </section>

                  <section aria-labelledby="options-heading" className="mt-8">
                    <h3 id="options-heading" className="sr-only">
                      Recommendation
                    </h3>

                    {recommendation?.customer_recommendation_p ? (
                      <div className="mt-6">
                        <h4 className="sr-only">Recommendation</h4>

                        <p className="text-sm text-gray-700">{recommendation?.customer_message}</p>
                      </div>
                    ) : null}

                    <button
                      type="submit"
                      className="bg-colorPrimary text-textPrimary px-2 w-full rounded-md py-3 mt-6"
                      style={{ backgroundColor: theme?.general?.buttonColor || '' }}
                      onClick={() => onAddMenuItem(recommendedItem as TMenuItem)}
                    >
                      Add
                    </button>
                  </section>
                </div>
              </div> */}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default RecommendationModal;
